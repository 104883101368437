import { BaseDto } from "@/shared/dtos/base-dto";

export class pantalla_lista extends BaseDto {
  public nombre!: string;
  public anyadir!: boolean;
  public editar!: boolean;
  public borrar!: boolean;
  public api_call!: string;
  public permission_new!: string;
  public permission_update!: string;
  public permission_delete!: string;
  public router_name!: string;
  public sub_tabla!: boolean;
  public descripcion!: string;
}
