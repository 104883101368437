

















import { RouterNames } from "@/router/routernames";
import { pantalla_lista } from "@/shared/dtos/dynamic_tables/pantalla_lista";
import { MessageService } from "@/shared/services/message-service";
import pantalla_modo_listaModule from "@/store/modules/pantalla_modo_lista-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    DynamicTable: () => import("@/views/dynamic_tables/dynamic_table.vue"),
  },
})
export default class DynamicTableLista extends Vue {
  nuevo() {
    MessageService.confirm(
      "Creacion de tabla",
      "¿Seguro que quiere crear una nueva tabla?",
      async (cb: boolean) => {
        if (cb) {
          let objecto: pantalla_lista = await pantalla_modo_listaModule.guardarpantalla_modo_lista(
            new pantalla_lista({
              id: 0,
              nombre: "string",
              anyadir: true,
              editar: true,
              borrar: true,
              api_call: "string",
            })
          );
          this.abrir_formulario(objecto.id);
        }
      }
    );
  }

  editar(obj: pantalla_lista) {
    this.abrir_formulario(UtilsString.ValueOf(obj.id));
  }

  eliminar(obj: pantalla_lista) {
    pantalla_modo_listaModule
      .eliminarpantalla_modo_lista(obj.id)
      .then(() => {
        //@ts-ignore
        this.$refs["data_table"].refresh();
      })
      .catch(() => {
        MessageService.toast(
          "No se ha podido eliminar, hay datos asociados",
          MessageService.TypeWarning
        );
      });
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.tablasformulario,
      params: { id: id },
    });
  }
}
